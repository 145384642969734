/*
 You probably do not need to edit this at all.

 Add some SmartMenus required styles not covered in Bootstrap 3's default CSS.
 These are theme independent and should work with any Bootstrap 3 theme mod.
*/
/* sub menus arrows */
.navbar-nav .sub-arrow, .navbar-nav .collapsible .sub-arrow {
	position:static;
	margin-top:0;
	margin-right:0;
	margin-left:6px;
	display:inline-block;
	width:0;
	height:0;
	overflow:hidden;
	vertical-align:middle;
	border-top:4px solid;
	border-right:4px dashed transparent;
	border-bottom:4px dashed transparent;
	border-left:4px dashed transparent;
}
.navbar-fixed-bottom .sub-arrow {
	margin-top:-5px;
	border-top:4px dashed transparent;
	border-bottom:4px solid;
}
.navbar-nav ul .sub-arrow {
	position:absolute;
	right:0;
	margin-top:6px;
	margin-right:15px;
	border-top:4px dashed transparent;
	border-bottom:4px dashed transparent;
	border-left:4px solid;
}
.navbar-nav ul a.has-submenu {
	padding-right:30px;
}
/* scrolling arrows for tall menus */
.navbar-nav span.scroll-up, .navbar-nav span.scroll-down {
	position:absolute;
	display:none;
	visibility:hidden;
	height:20px;
	overflow:hidden;
	text-align:center;
}
.navbar-nav span.scroll-up-arrow, .navbar-nav span.scroll-down-arrow {
	position:absolute;
	top:-2px;
	left:50%;
	margin-left:-8px;
	width:0;
	height:0;
	overflow:hidden;
	border-top:7px dashed transparent;
	border-right:7px dashed transparent;
	border-bottom:7px solid;
	border-left:7px dashed transparent;
}
.navbar-nav span.scroll-down-arrow {
	top:6px;
	border-top:7px solid;
	border-right:7px dashed transparent;
	border-bottom:7px dashed transparent;
	border-left:7px dashed transparent;
}
/* add more indentation for 2+ level sub in collapsible mode - Bootstrap normally supports just 1 level sub menus */
.navbar-nav .collapsible ul .dropdown-menu > li > a,
.navbar-nav .collapsible ul .dropdown-menu .dropdown-header {
	padding-left:35px;
}
.navbar-nav .collapsible ul ul .dropdown-menu > li > a,
.navbar-nav .collapsible ul ul .dropdown-menu .dropdown-header {
	padding-left:45px;
}
.navbar-nav .collapsible ul ul ul .dropdown-menu > li > a,
.navbar-nav .collapsible ul ul ul .dropdown-menu .dropdown-header {
	padding-left:55px;
}
.navbar-nav .collapsible ul ul ul ul .dropdown-menu > li > a,
.navbar-nav .collapsible ul ul ul ul .dropdown-menu .dropdown-header {
	padding-left:65px;
}
/* fix SmartMenus sub menus auto width (subMenusMinWidth and subMenusMaxWidth options) */
.navbar-nav .dropdown-menu > li > a {
	white-space:normal;
}
.navbar-nav ul.sm-nowrap > li > a {
	white-space:nowrap;
}
/* fix .navbar-right subs alignment */
.navbar-right ul.dropdown-menu {
	left:0;
	right:auto;
}
/* The following will make the sub menus collapsible for small screen devices (it's not recommended editing these) */
.navbar-nav .collapsible ul {display:none;position:static !important;top:auto !important;left:auto !important;margin-left:0 !important;margin-top:0 !important;width:auto !important;min-width:0 !important;max-width:none !important;}
.navbar-nav .collapsible ul.sm-nowrap > li > a {white-space:normal;}
.navbar-nav .collapsible iframe{display:none;}
/* disable Bootstrap 3's global box-sizing:border-box; for the menus as it doesn't play nice with SmartMenus */
ul.sm li *,ul.sm li *:before,ul.sm li *:after{-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;}