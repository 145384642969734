.navbar-nav .sub-arrow, .navbar-nav .collapsible .sub-arrow {
  vertical-align: middle;
  border: 4px dashed #0000;
  border-top: 4px solid;
  width: 0;
  height: 0;
  margin-top: 0;
  margin-left: 6px;
  margin-right: 0;
  display: inline-block;
  position: static;
  overflow: hidden;
}

.navbar-fixed-bottom .sub-arrow {
  border-top: 4px dashed #0000;
  border-bottom: 4px solid;
  margin-top: -5px;
}

.navbar-nav ul .sub-arrow {
  border-top: 4px dashed #0000;
  border-bottom: 4px dashed #0000;
  border-left: 4px solid;
  margin-top: 6px;
  margin-right: 15px;
  position: absolute;
  right: 0;
}

.navbar-nav ul a.has-submenu {
  padding-right: 30px;
}

.navbar-nav span.scroll-up, .navbar-nav span.scroll-down {
  visibility: hidden;
  text-align: center;
  height: 20px;
  display: none;
  position: absolute;
  overflow: hidden;
}

.navbar-nav span.scroll-up-arrow, .navbar-nav span.scroll-down-arrow {
  border: 7px dashed #0000;
  border-bottom: 7px solid;
  width: 0;
  height: 0;
  margin-left: -8px;
  position: absolute;
  top: -2px;
  left: 50%;
  overflow: hidden;
}

.navbar-nav span.scroll-down-arrow {
  border: 7px dashed #0000;
  border-top: 7px solid;
  top: 6px;
}

.navbar-nav .collapsible ul .dropdown-menu > li > a, .navbar-nav .collapsible ul .dropdown-menu .dropdown-header {
  padding-left: 35px;
}

.navbar-nav .collapsible ul ul .dropdown-menu > li > a, .navbar-nav .collapsible ul ul .dropdown-menu .dropdown-header {
  padding-left: 45px;
}

.navbar-nav .collapsible ul ul ul .dropdown-menu > li > a, .navbar-nav .collapsible ul ul ul .dropdown-menu .dropdown-header {
  padding-left: 55px;
}

.navbar-nav .collapsible ul ul ul ul .dropdown-menu > li > a, .navbar-nav .collapsible ul ul ul ul .dropdown-menu .dropdown-header {
  padding-left: 65px;
}

.navbar-nav .dropdown-menu > li > a {
  white-space: normal;
}

.navbar-nav ul.sm-nowrap > li > a {
  white-space: nowrap;
}

.navbar-right ul.dropdown-menu {
  left: 0;
  right: auto;
}

.navbar-nav .collapsible ul {
  display: none;
  width: auto !important;
  min-width: 0 !important;
  max-width: none !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  position: static !important;
  top: auto !important;
  left: auto !important;
}

.navbar-nav .collapsible ul.sm-nowrap > li > a {
  white-space: normal;
}

.navbar-nav .collapsible iframe {
  display: none;
}

ul.sm li *, ul.sm li :before, ul.sm li :after {
  box-sizing: content-box;
}
/*# sourceMappingURL=index.38822a65.css.map */
